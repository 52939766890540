/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link ,BrowserRouter as Router,Route} from "react-router-dom";
import { GlobalSet } from '../../components/global/GlobalProvider';
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import axios from "axios";


class MM extends Component {
	componentDidMount() {
		this.$el = this.el;
		this.mm = new Metismenu(this.$el);
	}
  componentWillUnmount() {
  }
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () =>{
    const { BaseUrl } = GlobalSet();

	  const {
  		iconHover,
  		sidebarposition,
  		headerposition,
  		sidebarLayout,
	  } = useContext(ThemeContext);
  useEffect(() => {
    //menu-toggle
      var btn = document.querySelector(".nav-control");
      btn.addEventListener("click", toggleFunc);
      var aaa = document.querySelector("#main-wrapper");
      function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }

  //all-game-tournaments

     //sidebar icon Heart blast
        var handleheartBlast = document.querySelector('.heart');
        function heartBlast() {
            return handleheartBlast.classList.toggle("heart-blast");
        }
        handleheartBlast.addEventListener('click', heartBlast);
	
  }, []);

  // Function to toggle menu
  const toggleMenu = () => {
    const aaa = document.querySelector("#main-wrapper");
    if (aaa) {
      aaa.classList.remove("menu-toggle");
    }
  };


 //For scroll
 const [hideOnScroll, setHideOnScroll] = useState(true)
	useScrollPosition(
		({ prevPos, currPos }) => {
		  const isShow = currPos.y > prevPos.y
		  if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)
  /// Path
  let path = window.location.pathname;

  /// Active menu
  let deshBoard = [
      "",
      "Game",
      "lottery",
      ],
    sports = [
     "sports/:id/:matches_slug"
      
    ]

  return (
  <div
  className={`dlabnav z-3 ${iconHover} ${
  sidebarposition.value === "fixed" &&
  sidebarLayout.value === "horizontal" &&
  headerposition.value === "static"
    ? hideOnScroll > 120
      ? "fixed"
      : ""
    : ""
  }`}
  >
  <PerfectScrollbar className="dlabnav-scroll">
  <MM className="metismenu" id="menu">

      <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
        <Link to="/"  onClick={toggleMenu} className="ai-icon" >
          <i className="fas fa-home"></i>
          <span className="nav-text">DeshBoard</span>
        </Link>
      </li>


      <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
          <Link className="has-arrow" to="#" >
            <i className="fas fa-window-maximize"></i>
            <span className="nav-text">Fund</span>
          </Link>
          <ul>
            <li>
               <Link to="/deposit" onClick={toggleMenu}>Deposit
               </Link>
            </li>
{/*            <li>
               <Link to="/convert-balance" onClick={toggleMenu}>Convert Balance
               </Link>
            </li> */}
            <li>
               <Link to="/balance-transfer" onClick={toggleMenu}>Balance Transfer
               </Link>
            </li> 
            <li>
               <Link to="/withdraw" onClick={toggleMenu}>Withdraw Asset
               </Link>
            </li>            
          </ul>
      </li>


 {/*  <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
          <Link className="has-arrow" to="#" >
            <i className="fas fa-chart-line"></i>
            <span className="nav-text">Migration</span>
          </Link>
          <ul>
            <li>
              <Link to="/trading" onClick={toggleMenu}>Trading
               </Link>
               <Link to="/package-list" onClick={toggleMenu}>Package List
               </Link>
            </li>          
          </ul>
      </li>*/}


      <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
        <Link to="/package-list"  onClick={toggleMenu} className="ai-icon" >
          <i className="fas fa-chart-line"></i>
          <span className="nav-text">Package List</span>
        </Link>
      </li>



      <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
          <Link className="has-arrow" to="#" >
            <i className="far fa-file-alt"></i>
            <span className="nav-text">Report</span>
          </Link>
          <ul>
    {/*     <li>
               <Link to="/trading-history" onClick={toggleMenu}>Trading History
               </Link>
            </li>*/}
            <li>
               <Link to="/income-history" onClick={toggleMenu}>Income History
               </Link>
            </li>
            <li>
               <Link to="/transaction-history" onClick={toggleMenu}>Transaction History
               </Link>
            </li> 
            <li>
               <Link to="/investing-log" onClick={toggleMenu}>Investing Log
               </Link>
            </li>           
          </ul>
      </li>


      <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
          <Link className="has-arrow" to="#" >
            <i className="fas fa-lock"></i>
            <span className="nav-text">Security</span>
          </Link>
          <ul>
            <li>
               <Link to="/change-password" onClick={toggleMenu}>Change password
               </Link>
            </li>
            <li>
               <Link to="/security" onClick={toggleMenu}>Security
               </Link>
            </li>            
          </ul>
      </li>



      <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
          <Link className="has-arrow" to="#" >
            <i className="fa fa-users"></i>
            <span className="nav-text">Partner</span>
          </Link>
          <ul>
            <li>
               <Link to="/affiliate" onClick={toggleMenu}>Partner
               </Link>
               <Link to="/my-smart-team" onClick={toggleMenu}>Smart Team
               </Link>
            </li>            
          </ul>
      </li>


{/*
      <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
          <Link className="has-arrow" to="#" >
            <i className="fas fa-chart-line"></i>
            <span className="nav-text">Live Trading</span>
          </Link>
          <ul>

            <li>
               <Link to="/currency-eur" onClick={toggleMenu}>EUR
               </Link>
            </li>
            <li>
               <Link to="/currency-xau" onClick={toggleMenu}>XAU
               </Link>
            </li>
            <li>
               <Link to="/currency-gbp" onClick={toggleMenu}>GBP
               </Link>
            </li>
            <li>
               <Link to="/currency-btc" onClick={toggleMenu}>BTC
               </Link>
            </li>   
            <li>
               <Link to="/currency-eth" onClick={toggleMenu}>ETH
               </Link>
            </li>
            <li>
               <Link to="/currency-bnb" onClick={toggleMenu}>BNB
               </Link>
            </li>             
          </ul>
      </li>
*/}


      <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
          <Link className="has-arrow" to="#" >
            <i className="fa fa-info-circle"></i>
            <span className="nav-text">Support Center</span>
          </Link>
          <ul>
            <li>
               <Link to="/support-ticket" onClick={toggleMenu}>Support Ticket
               </Link>
            </li>           
          </ul>
      </li>




  </MM>
  <div className="dropdown header-profile2 ">
  	<div className="header-info2 text-center">
  		<div>
  			<Link to={"support-ticket"} className="btn btn-md text-secondary">Contact Us</Link>
  		</div>
  	</div>
  </div>
  <div className="copyright">
  	<p className="text-center"><strong>Hostpup</strong>Copyright  © All Rights Reserved 2004</p>
  	<p className="fs-12 text-center">Made with <span className="heart"></span> by Hostpup</p>
  </div>
  </PerfectScrollbar>
  </div>
  );
};

export default SideBar;
