import React, { Fragment,useState,useEffect } from "react";
import { Form } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import { useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

const Checkout = () => {


const [depositInput, setDepositInput] = useState({
  amount: '',
  password: '',
  error_list: [],
});
const handleInput = (e) => {
  e.persist();
  setDepositInput({...depositInput, [e.target.name]: e.target.value });
}

const [selectedPaymentTyp, setSelectedPaymentTtyp] = useState('easy_package');
const [loading, setLoading] = useState(false);
const depositSubmit = (e) => {
  e.preventDefault();
  const auth_token = localStorage.getItem('auth_token');
  const data = {
      amount: selectedPaymentTyp,
      password: depositInput.password,
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9'
  }
 if (auth_token !== null && !loading) {
  setLoading(true);
 axios.post(`/sub-invest`, data).then(res => {
  setLoading(false);
       if(res.data.status === 'success')
       {
         swal("Success",res.data.message,"success");
         setDepositInput({...depositInput,
           amount:'',
           password:'',
           
         });
       }
       else if(res.data.status === 'fail')
       {
         swal("Warning",res.data.message,"warning");
       }
       else 
       {
       setDepositInput({...depositInput, error_list: res.data.validation_error});
       }
       setLoading(false);
   });
 }
}

   return (
      <Fragment>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     <div className="row">
                        <div className="col-md-4 order-md-2 mb-4">
                           <h4 className="d-flex justify-content-between align-items-center mb-3">
                              <span className="text-muted">Asset Investing</span>
                              <span className="badge badge-primary badge-pill">
                                $
                              </span>
                           </h4>
                           <ul className="list-group mb-3">
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <h6 className="my-0">Investing</h6>
                                    <small className="text-muted">
                                       Investing To Principal Wallet
                                    </small>
                                 </div>
                                 <span className="text-muted">100%</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <small className="text-muted">
                                       Deposit Limit
                                    </small>
                                    <h6 className="my-0">The Investing balance will not get returned before 29 days </h6>
                                 </div>
                              </li>
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <small className="text-muted">
                                       Investing payment
                                    </small>                                 
                                    <h6 className="my-0">Investing</h6>

                                 </div>
                              </li>
                           </ul>
                        </div>
                        <div className="col-md-8 order-md-1">
                           <h4 className="mb-3">Asset Investing </h4>
                           <form onSubmit={depositSubmit} className="needs-validation" noValidate="">
                              <div className="row">



                      <div className="col-md-12 mb-3">
                        <div className="row" style={{margin: "0px auto"}}>


                            <div className="col-xl-4 col-xxl-4 col-4 p-1">
                                <div style={{ background: "#e28628", color: '#707070' }} className="card booking">
                                    <div style={{ textAlign: 'center' }}>
                                        <label data-kt-button="true">
                                            <div style={{ marginTop: "20px" }} className="booking-status d-flex align-items-center">
                                                <div>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600">Easy Package</h5>
                                                </div>
                                            </div>
                                            <div className="booking-status d-flex align-items-center">
                                                <div style={{ margin: '0px auto' }}>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600  align-items-center">$100</h5>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="grid-product__content">
                                                    <p style={{ marginBottom: '0px' }} className="title"></p>
                                                    <input
                                                        className="form-check-input border border-gray-500"
                                                        type="radio"
                                                        name="amount"
                                                        value="easy_package"
                                                        checked={selectedPaymentTyp === "easy_package"}
                                                        onChange={() => setSelectedPaymentTtyp("easy_package")}
                                                    />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-xxl-4 col-4 p-1">
                                <div style={{ background: "#e28628", color: '#707070' }} className="card booking">
                                    <div style={{ textAlign: 'center' }}>
                                        <label data-kt-button="true">
                                            <div style={{ marginTop: "20px" }} className="booking-status d-flex align-items-center">
                                                <div>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600">Starter Package</h5>
                                                </div>
                                            </div>
                                            <div className="booking-status d-flex align-items-center">
                                                <div style={{ margin: '0px auto' }}>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600  align-items-center">$500</h5>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="grid-product__content">
                                                    <p style={{ marginBottom: '0px' }} className="title"></p>
                                                    <input
                                                        className="form-check-input border border-gray-500"
                                                        type="radio"
                                                        name="amount"
                                                        value="starter_package"
                                                        checked={selectedPaymentTyp === "starter_package"}
                                                        onChange={() => setSelectedPaymentTtyp("starter_package")}
                                                    />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div className="col-xl-4 col-xxl-4 col-4 p-1">
                                <div style={{ background: "#e28628", color: '#707070' }} className="card booking">
                                    <div style={{ textAlign: 'center' }}>
                                        <label data-kt-button="true">
                                            <div style={{ marginTop: "20px" }} className="booking-status d-flex align-items-center">
                                                <div>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600">Silver Package</h5>
                                                </div>
                                            </div>
                                            <div className="booking-status d-flex align-items-center">
                                                <div style={{ margin: '0px auto' }}>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600  align-items-center">$1000</h5>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="grid-product__content">
                                                    <p style={{ marginBottom: '0px' }} className="title"></p>
                                                    <input
                                                        className="form-check-input border border-gray-500"
                                                        type="radio"
                                                        name="amount"
                                                        value="silver_package"
                                                        checked={selectedPaymentTyp === "silver_package"}
                                                        onChange={() => setSelectedPaymentTtyp("silver_package")}
                                                    />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>



                            <div className="col-xl-4 col-xxl-4 col-4 p-1">
                                <div style={{ background: "#e28628", color: '#707070' }} className="card booking">
                                    <div style={{ textAlign: 'center' }}>
                                        <label data-kt-button="true">
                                            <div style={{ marginTop: "20px" }} className="booking-status d-flex align-items-center">
                                                <div>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600">Gold Package</h5>
                                                 </div>
                                            </div>
                                            <div className="booking-status d-flex align-items-center">
                                                <div style={{ margin: '0px auto' }}>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600  align-items-center">$2000</h5>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="grid-product__content">
                                                    <p style={{ marginBottom: '0px' }} className="title"></p>
                                                    <input
                                                        className="form-check-input border border-gray-500"
                                                        type="radio"
                                                        name="amount"
                                                        value="gold_package"
                                                        checked={selectedPaymentTyp === "gold_package"}
                                                        onChange={() => setSelectedPaymentTtyp("gold_package")}
                                                    />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>



                            <div className="col-xl-4 col-xxl-4 col-4 p-1">
                                <div style={{ background: "#e28628", color: '#707070' }} className="card booking">
                                    <div style={{ textAlign: 'center' }}>
                                        <label data-kt-button="true">
                                            <div style={{ marginTop: "20px" }} className="booking-status d-flex align-items-center">
                                                <div>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600">Platinum Package</h5>
                                                </div>
                                            </div>
                                            <div className="booking-status d-flex align-items-center">
                                                <div style={{ margin: '0px auto' }}>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600  align-items-center">$5000</h5>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="grid-product__content">
                                                    <p style={{ marginBottom: '0px' }} className="title"></p>
                                                    <input
                                                        className="form-check-input border border-gray-500"
                                                        type="radio"
                                                        name="amount"
                                                        value="platinum_package"
                                                        checked={selectedPaymentTyp === "platinum_package"}
                                                        onChange={() => setSelectedPaymentTtyp("platinum_package")}
                                                    />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-xxl-4 col-4 p-1">
                                <div style={{ background: "#e28628", color: '#707070' }} className="card booking">
                                    <div style={{ textAlign: 'center' }}>
                                        <label data-kt-button="true">
                                            <div style={{ marginTop: "20px" }} className="booking-status d-flex align-items-center">
                                                <div>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600">Professional Package</h5>
                                                </div>
                                            </div>
                                            <div className="booking-status d-flex align-items-center">
                                                <div style={{ margin: '0px auto' }}>
                                                    <h5 style={{ maxWidth: '200px', color: '#707070' }} className="mb-0 font-w600  align-items-center">$10000</h5>
                                                </div>
                                            </div>
                                            <div>
                                                <div className="grid-product__content">
                                                    <p style={{ marginBottom: '0px' }} className="title"></p>
                                                    <input
                                                        className="form-check-input border border-gray-500"
                                                        type="radio"
                                                        name="amount"
                                                        value="professional_package"
                                                        checked={selectedPaymentTyp === "professional_package"}
                                                        onChange={() => setSelectedPaymentTtyp("professional_package")}
                                                    />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                            </div>



                        </div>
                      </div>






                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="firstName">
                                       Account Password
                                    </label>
                                    <input
                                       type="password"
                                       className="form-control"
                                       id="firstName"
                                       placeholder="Password"
                                       required
                                       name="password"
                                       onChange={handleInput} 
                                       value={depositInput.password}
                                    />
                                       <span className="text-danger">{depositInput.error_list.password}</span>
                                    
                                 </div>
                              </div>

                              <hr className="mb-4" />
                              <div className="form-check custom-checkbox mb-2">
                                 <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="same-address"
                                    required

                                 />
                                 <label
                                    className="form-check-label"
                                    htmlFor="same-address"
                                 >
                                    Accept treams & condition
                                 </label>
                              </div>
                              <hr className="mb-4" />
                              <button
                                 className="btn btn-warning btn-lg btn-block"
                                 type="submit"
                                 disabled={loading}
                              >
                              {loading ? 'Submitting...' : 'Investing Continue'}
                              </button>

                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default Checkout;
